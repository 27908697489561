@charset "UTF-8";
/*------------------------------------*\
    #IMPORTS PRE_RENDER — Must be after reset
\*------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

button, input {
  line-height: normal;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  *overflow: visible;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/*------------------------------------*\
    #COLOURS
\*------------------------------------*/
/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
    #SPACES
\*------------------------------------*/
/*------------------------------------*\
    #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*\
    #GLOBAL TYPOGRAPHY
\*------------------------------------*/
/*------------------------------------*\
    #FONTS
\*------------------------------------*/
.font-body, body, button, .form legend {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-body-italic, em,
i {
  font-family: sans-serif;
  font-weight: 400;
  font-style: italic;
  text-shadow: 0 0 0.1em rgba(31, 28, 44, 0.5);
}

.font-body-bold, .sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a, b,
strong {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
  text-shadow: 0 0 0.1em rgba(31, 28, 44, 0.5);
}

.font-body-bold-italic, em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  font-family: sans-serif;
  font-weight: 700;
  font-style: italic;
}

.font-var, .nav a, h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-var-bold, .wrapper--home-temp li a,
.wrapper--home-temp p, .wrapper--home-temp h1,
.wrapper--home-temp h2, .full-intro li a,
.full-intro p, .full-intro h1,
.full-intro h2, h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
}

/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/
/*------------------------------------*\
    #FONTSIZES
\*------------------------------------*/
/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 23.1404958678px;
  line-height: 1.25;
}
@media screen and (min-width: 799px) {
  h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 30.8px;
    line-height: 1.25;
  }
}

h3,
h4,
h5,
h6 {
  font-size: 23.1404958678px;
  line-height: 1.25;
  padding: 0.3em;
}
@media screen and (min-width: 799px) {
  h3,
h4,
h5,
h6 {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  h3,
h4,
h5,
h6 {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  h3,
h4,
h5,
h6 {
    font-size: 30.8px;
    line-height: 1.25;
  }
}

p {
  font-size: 14.8760330579px;
  line-height: 1.45;
  margin-bottom: 0.5em;
}
@media screen and (min-width: 799px) {
  p {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  p {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  p {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

blockquote p {
  text-align: center;
}

a {
  color: #a9b5a1;
  border-bottom: 1px solid #a9b5a1;
  padding-bottom: 0.1em;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #a9b5a1;
}

p sub,
p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small {
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  p small {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  p small {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  p small {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

p abbr {
  border-bottom: 1px dotted #1f1c2c;
  cursor: help;
}

p q:before,
p cite:before {
  content: '"';
}
p q:after,
p cite:after {
  content: '"';
}

p mark {
  background-color: rgba(169, 181, 161, 0.2);
  color: #1f1c2c;
}

p code,
p samp {
  font-family: monospace;
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  p code,
p samp {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  p code,
p samp {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  p code,
p samp {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

pre {
  font-family: monospace;
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  pre {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  pre {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  pre {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

hr {
  background: #1f1c2c;
  height: 1px;
  border: none;
  margin: 0;
}

figcaption {
  font-size: 14.8760330579px;
  line-height: 1.45;
  margin-top: 15px/1.21;
}
@media screen and (min-width: 799px) {
  figcaption {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  figcaption {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  figcaption {
    font-size: 19.8px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 799px) {
  figcaption {
    margin-top: 13.6363636364px;
  }
}
@media screen and (min-width: 1099px) {
  figcaption {
    margin-top: 15px;
  }
}
@media screen and (min-width: 1499px) {
  figcaption {
    margin-top: 16.5px;
  }
}

dl dt {
  font-size: 14.8760330579px;
  line-height: 1.45;
  margin-bottom: 15px/1.21;
}
@media screen and (min-width: 799px) {
  dl dt {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  dl dt {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  dl dt {
    font-size: 19.8px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 799px) {
  dl dt {
    margin-bottom: 13.6363636364px;
  }
}
@media screen and (min-width: 1099px) {
  dl dt {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1499px) {
  dl dt {
    margin-bottom: 16.5px;
  }
}
dl dt:first-of-type {
  padding-top: 20px/1.21;
  border-top: 1px #1f1c2c solid;
}
@media screen and (min-width: 799px) {
  dl dt:first-of-type {
    padding-top: 18.1818181818px;
  }
}
@media screen and (min-width: 1099px) {
  dl dt:first-of-type {
    padding-top: 20px;
  }
}
@media screen and (min-width: 1499px) {
  dl dt:first-of-type {
    padding-top: 22px;
  }
}
dl dd {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px #1f1c2c solid;
}

ol,
ul,
pre,
dl {
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  ol,
ul,
pre,
dl {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  ol,
ul,
pre,
dl {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  ol,
ul,
pre,
dl {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

ul,
ol {
  list-style-type: none;
}

ul ul {
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  ul ul {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  ul ul {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  ul ul {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

ol ol {
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  ol ol {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  ol ol {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  ol ol {
    font-size: 19.8px;
    line-height: 1.45;
  }
}

.text ul {
  list-style-position: outside;
  list-style-type: disc;
  margin-left: 1em;
}

.text ol {
  list-style-position: outside;
  list-style-type: decimal;
  margin-left: 1em;
}

.text > ul,
.text > ol {
  margin-bottom: 1em;
}

li {
  line-height: inherit;
}

input,
textarea,
label,
select {
  line-height: 1.428571429;
}

label, legend, select {
  display: block;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input[type=radio],
input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  *margin-top: 0;
  line-height: normal;
  cursor: pointer;
}

input[type=file],
input[type=image],
input[type=submit],
input[type=reset],
input[type=button],
input[type=radio],
input[type=checkbox] {
  width: auto;
}

select,
input[type=file] {
  *margin-top: 4px;
  /* In IE7, the height of the select element cannot be changed by height, only font-size */
}

select:focus,
input[type=file]:focus,
input[type=radio]:focus,
input[type=checkbox]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.form-control {
  transition: border-color 0.3s;
  width: 100%;
  border: 1px solid #d7d9d4;
  border-radius: 2px;
  box-shadow: none;
  padding: 6px;
}
.form-control:focus {
  border-color: #9acce2;
  box-shadow: none;
}
.form-control:hover {
  border-color: #eceeed;
}

select.form-control {
  appearance: none;
  transition: border-color 0.3s;
  border: 1px solid #d7d9d4;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE1LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMCAyMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+Cjxwb2x5Z29uIHBvaW50cz0iMTAsMTEuMiA2LjQsNy42IDQuMyw3LjYgMTAsMTMuMyAxNS43LDcuNiAxMy42LDcuNiAiLz4KPC9zdmc+Cg==);
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: auto 16px;
  max-width: 100%;
}
select.form-control:hover {
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
  border-color: #999999;
}

label {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}
.checkbox label, .radio label {
  font-size: 18px;
  line-height: 1.7;
}

/*------------------------------------*\
  #FORMS
\*------------------------------------*/
.form legend {
  font-size: 14.8760330579px;
  line-height: 1.45;
  text-align: center;
  color: #1f1c2c;
  border: none;
  margin-bottom: 15px/1.21;
}
@media screen and (min-width: 799px) {
  .form legend {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  .form legend {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  .form legend {
    font-size: 19.8px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 799px) {
  .form legend {
    margin-bottom: 13.6363636364px;
  }
}
@media screen and (min-width: 1099px) {
  .form legend {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1499px) {
  .form legend {
    margin-bottom: 16.5px;
  }
}
@media (min-width: 800px) {
  .form .inline-container {
    display: flex;
    align-items: stretch;
  }
}
.form input {
  flex: 1 auto;
  height: 100%;
  border: 1px solid #1f1c2c;
  padding: 15px;
  border-radius: 0;
  font-size: 14.8760330579px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  .form input {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  .form input {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  .form input {
    font-size: 19.8px;
    line-height: 1.45;
  }
}
.form input:focus {
  outline: none;
  border: 1px solid #1f1c2c;
}
.form input:hover {
  border: 1px solid #1f1c2c;
}
@media (max-width: 799px) {
  .form input {
    width: 100%;
  }
}
.form .button {
  font-size: 14.8760330579px;
  line-height: 1.45;
  white-space: nowrap;
  min-width: 150px;
}
@media screen and (min-width: 799px) {
  .form .button {
    font-size: 16.3636363636px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  .form .button {
    font-size: 18px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  .form .button {
    font-size: 19.8px;
    line-height: 1.45;
  }
}
@media (max-width: 799px) {
  .form .button {
    width: 100%;
    margin-top: -1px;
  }
}
@media (min-width: 800px) {
  .form .button {
    margin-left: -1px;
  }
}

/*-----------------------------------*\

  $TOAST-GRID

  An insane grid.
  You'd be mad to use it.

  Usage
  =====

  Assuming default values:

  <div class="grid">
    <div class="grid__col grid__col--1-of-2">
      A half-width column.
    </div>
    <div class="grid__col grid__col--1-of-4 grid__col--pull-1-of-4">
      A quarter, pulled left by its own width. You get this, right?
    </div>
  </div>


  Customisation
  =============

  $toast-grid-namespace and $toast-grid-column-namespace
  adjusts the class names for the grid. With
  default values, grid wrappers have a class
  of '.grid' and columns '.grid__col'.

  $toast-col-groups(n) adjusts column divisions.
  For example, $toast-col-groups(12) will produce
  a 12-column grid. $col-groups(3,6,8)
  will produce a 3-, 6-, and 8-column grid.

  $toast-gutter-width is—you guessed it—the gutter
  width. Accepts any unit.

  That's it. Have fun.

\*-----------------------------------*/
.grid {
  list-style: none;
  margin-left: -20px;
}

.grid__col--12-of-12, .grid__col--8-of-8, .grid__col--6-of-6, .grid__col--5-of-5, .grid__col--4-of-4, .grid__col--3-of-3, .grid__col--2-of-2 {
  width: 100%;
}

.grid__col--6-of-12, .grid__col--4-of-8, .grid__col--3-of-6, .grid__col--2-of-4, .grid__col--1-of-2 {
  width: 50%;
}

.grid__col--4-of-12, .grid__col--2-of-6, .grid__col--1-of-3 {
  width: 33.3333333333%;
}

.grid__col--8-of-12, .grid__col--4-of-6, .grid__col--2-of-3 {
  width: 66.6666666667%;
}

.grid__col--3-of-12, .grid__col--2-of-8, .grid__col--1-of-4 {
  width: 25%;
}

.grid__col--9-of-12, .grid__col--6-of-8, .grid__col--3-of-4 {
  width: 75%;
}

.grid__col--push-12-of-12, .grid__col--push-8-of-8, .grid__col--push-6-of-6, .grid__col--push-5-of-5, .grid__col--push-4-of-4, .grid__col--push-3-of-3, .grid__col--push-2-of-2 {
  margin-left: 100%;
}

.grid__col--push-6-of-12, .grid__col--push-4-of-8, .grid__col--push-3-of-6, .grid__col--push-2-of-4, .grid__col--push-1-of-2 {
  margin-left: 50%;
}

.grid__col--push-4-of-12, .grid__col--push-2-of-6, .grid__col--push-1-of-3 {
  margin-left: 33.3333333333%;
}

.grid__col--push-8-of-12, .grid__col--push-4-of-6, .grid__col--push-2-of-3 {
  margin-left: 66.6666666667%;
}

.grid__col--push-3-of-12, .grid__col--push-2-of-8, .grid__col--push-1-of-4 {
  margin-left: 25%;
}

.grid__col--push-9-of-12, .grid__col--push-6-of-8, .grid__col--push-3-of-4 {
  margin-left: 75%;
}

.grid__col--pull-12-of-12, .grid__col--pull-8-of-8, .grid__col--pull-6-of-6, .grid__col--pull-5-of-5, .grid__col--pull-4-of-4, .grid__col--pull-3-of-3, .grid__col--pull-2-of-2 {
  margin-left: -100%;
}

.grid__col--pull-6-of-12, .grid__col--pull-4-of-8, .grid__col--pull-3-of-6, .grid__col--pull-2-of-4, .grid__col--pull-1-of-2 {
  margin-left: -50%;
}

.grid__col--pull-4-of-12, .grid__col--pull-2-of-6, .grid__col--pull-1-of-3 {
  margin-left: -33.3333333333%;
}

.grid__col--pull-8-of-12, .grid__col--pull-4-of-6, .grid__col--pull-2-of-3 {
  margin-left: -66.6666666667%;
}

.grid__col--pull-3-of-12, .grid__col--pull-2-of-8, .grid__col--pull-1-of-4 {
  margin-left: -25%;
}

.grid__col--pull-9-of-12, .grid__col--pull-6-of-8, .grid__col--pull-3-of-4 {
  margin-left: -75%;
}

.grid__col--1-of-5 {
  width: 20%;
}

.grid__col--push-1-of-5 {
  margin-left: 20%;
}

.grid__col--pull-1-of-5 {
  margin-left: -20%;
}

.grid__col--2-of-5 {
  width: 40%;
}

.grid__col--push-2-of-5 {
  margin-left: 40%;
}

.grid__col--pull-2-of-5 {
  margin-left: -40%;
}

.grid__col--3-of-5 {
  width: 60%;
}

.grid__col--push-3-of-5 {
  margin-left: 60%;
}

.grid__col--pull-3-of-5 {
  margin-left: -60%;
}

.grid__col--4-of-5 {
  width: 80%;
}

.grid__col--push-4-of-5 {
  margin-left: 80%;
}

.grid__col--pull-4-of-5 {
  margin-left: -80%;
}

.grid__col--1-of-6 {
  width: 16.6666666667%;
}

.grid__col--push-1-of-6 {
  margin-left: 16.6666666667%;
}

.grid__col--pull-1-of-6 {
  margin-left: -16.6666666667%;
}

.grid__col--5-of-6 {
  width: 83.3333333333%;
}

.grid__col--push-5-of-6 {
  margin-left: 83.3333333333%;
}

.grid__col--pull-5-of-6 {
  margin-left: -83.3333333333%;
}

.grid__col--1-of-8 {
  width: 12.5%;
}

.grid__col--push-1-of-8 {
  margin-left: 12.5%;
}

.grid__col--pull-1-of-8 {
  margin-left: -12.5%;
}

.grid__col--3-of-8 {
  width: 37.5%;
}

.grid__col--push-3-of-8 {
  margin-left: 37.5%;
}

.grid__col--pull-3-of-8 {
  margin-left: -37.5%;
}

.grid__col--5-of-8 {
  width: 62.5%;
}

.grid__col--push-5-of-8 {
  margin-left: 62.5%;
}

.grid__col--pull-5-of-8 {
  margin-left: -62.5%;
}

.grid__col--7-of-8 {
  width: 87.5%;
}

.grid__col--push-7-of-8 {
  margin-left: 87.5%;
}

.grid__col--pull-7-of-8 {
  margin-left: -87.5%;
}

.grid__col--1-of-12 {
  width: 8.3333333333%;
}

.grid__col--push-1-of-12 {
  margin-left: 8.3333333333%;
}

.grid__col--pull-1-of-12 {
  margin-left: -8.3333333333%;
}

.grid__col--2-of-12 {
  width: 16.6666666667%;
}

.grid__col--push-2-of-12 {
  margin-left: 16.6666666667%;
}

.grid__col--pull-2-of-12 {
  margin-left: -16.6666666667%;
}

.grid__col--5-of-12 {
  width: 41.6666666667%;
}

.grid__col--push-5-of-12 {
  margin-left: 41.6666666667%;
}

.grid__col--pull-5-of-12 {
  margin-left: -41.6666666667%;
}

.grid__col--7-of-12 {
  width: 58.3333333333%;
}

.grid__col--push-7-of-12 {
  margin-left: 58.3333333333%;
}

.grid__col--pull-7-of-12 {
  margin-left: -58.3333333333%;
}

.grid__col--10-of-12 {
  width: 83.3333333333%;
}

.grid__col--push-10-of-12 {
  margin-left: 83.3333333333%;
}

.grid__col--pull-10-of-12 {
  margin-left: -83.3333333333%;
}

.grid__col--11-of-12 {
  width: 91.6666666667%;
}

.grid__col--push-11-of-12 {
  margin-left: 91.6666666667%;
}

.grid__col--pull-11-of-12 {
  margin-left: -91.6666666667%;
}

.grid__col {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding-left: 20px;
  vertical-align: top;
}
@media (max-width: 800px) {
  .grid__col {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
}
@media (max-width: 800px) and (min-width: 600px) {
  .grid__col[class*=grid__col--m-] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid__col.grid__col--m-1-of-2, .grid__col.grid__col--m-2-of-4 {
    width: 50%;
  }
  .grid__col.grid__col--m-1-of-3 {
    width: 33.3333333333%;
  }
  .grid__col.grid__col--m-2-of-3 {
    width: 66.6666666667%;
  }
  .grid__col.grid__col--m-1-of-4 {
    width: 25%;
  }
  .grid__col.grid__col--m-3-of-4 {
    width: 75%;
  }
}
@media (max-width: 600px) {
  .grid__col[class*=grid__col--s-] {
    display: inline-block;
    margin-right: -0.24em;
  }
  .grid__col.grid__col--s-1-of-2, .grid__col.grid__col--s-2-of-4 {
    width: 50%;
  }
  .grid__col.grid__col--s-1-of-3 {
    width: 33.3333333333%;
  }
  .grid__col.grid__col--s-2-of-3 {
    width: 66.6666666667%;
  }
  .grid__col.grid__col--s-1-of-4 {
    width: 25%;
  }
  .grid__col.grid__col--s-3-of-4 {
    width: 75%;
  }
}

.grid__col--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.grid__col--d-first {
  float: left;
}

.grid__col--d-last {
  float: right;
}

.grid--no-gutter {
  margin-left: 0;
  width: 100%;
}
.grid--no-gutter .grid__col {
  padding-left: 0;
}
.grid--no-gutter .grid__col--span-all {
  margin-left: 0;
  width: 100%;
}

.grid__col--ab {
  vertical-align: bottom;
}

.grid__col--am {
  vertical-align: middle;
}

/*------------------------------------*\
    #PATTERNLAB Layout (à supprimer en production)
\*------------------------------------*/
.sg-viewport [role=main] {
  padding: 0.5em;
}

.sg-main {
  width: 94%;
  margin: auto;
}

.sg-colors {
  padding-left: 0;
  margin-bottom: 20px;
  font-size: 14px;
}
.sg-colors li {
  width: 10em !important;
}
.sg-colors .sg-swatch {
  height: 9em;
}

.sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a {
  font-size: 41.3223140496px;
  line-height: 1.25;
  color: #1f1c2c !important;
}
@media screen and (min-width: 799px) {
  .sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a {
    font-size: 45.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a {
    font-size: 50px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .sg-pattern-category-title a,
.sg-pattern-head .sg-pattern-title a {
    font-size: 55px;
    line-height: 1.25;
  }
}

.sg-pattern-head .sg-pattern-title a {
  font-size: 23.1404958678px;
  line-height: 1.25;
}
@media screen and (min-width: 799px) {
  .sg-pattern-head .sg-pattern-title a {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .sg-pattern-head .sg-pattern-title a {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .sg-pattern-head .sg-pattern-title a {
    font-size: 30.8px;
    line-height: 1.25;
  }
}

.sg-pattern-head .sg-pattern-title a {
  border: none;
}

.demo-animate {
  border-radius: 0 !important;
  background-color: #eceeed !important;
}

/*------------------------------------*\
    #ATOMS
\*------------------------------------*/
/*------------------------------------*\
  #GLOBAL
\*------------------------------------*/
/**
 * Global > colors
 */
.bg-black {
  background-color: #1f1c2c;
}

.bg-grey {
  background-color: #eceeed;
}

.bg-white {
  background-color: #ffffff;
}

.bg-blue {
  background-color: #0d4eff;
}

/**
 * Global > animations
 */
.animate-fade {
  transition: opacity 0s;
  opacity: 1;
}
.animate-fade:hover {
  opacity: 0;
}

/**
 * Global > visibilty
 */
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

@media all and (max-width: 1099px) {
  .hide-small {
    display: none;
  }
}

@media all and (min-width: 1100px) and (max-width: 1499px) {
  .hide-med {
    display: none;
  }
}

@media all and (min-width: 1500px) {
  .hide-large {
    display: none;
  }
}

/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/
.button {
  display: inline-block;
  background-color: #d7d9d4;
  border: 2px solid #a9b5a1;
  border-radius: 0;
}
.button:hover {
  opacity: 0.7;
  color: #1f1c2c;
  cursor: pointer;
}

.button--alt {
  background-color: #eceeed;
  border: 1px solid #1f1c2c;
  color: #1f1c2c;
}
.button--alt:hover {
  background-color: #eceeed;
  border: 1px solid #1f1c2c;
  color: #1f1c2c;
}

/*------------------------------------*\
  #IMAGES
\*------------------------------------*/
img,
video,
object {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}

[id^=attachment],
.image {
  width: 100% !important;
  margin-top: 30px/1.21;
  margin-bottom: 30px/1.21;
}
@media screen and (min-width: 799px) {
  [id^=attachment],
.image {
    margin-top: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  [id^=attachment],
.image {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1499px) {
  [id^=attachment],
.image {
    margin-top: 33px;
  }
}
@media screen and (min-width: 799px) {
  [id^=attachment],
.image {
    margin-bottom: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  [id^=attachment],
.image {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1499px) {
  [id^=attachment],
.image {
    margin-bottom: 33px;
  }
}
[id^=attachment] .wp-caption-text,
[id^=attachment] figcaption,
.image .wp-caption-text,
.image figcaption {
  margin-top: 0.3em;
  font-size: 13.2231404959px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  [id^=attachment] .wp-caption-text,
[id^=attachment] figcaption,
.image .wp-caption-text,
.image figcaption {
    font-size: 14.5454545455px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  [id^=attachment] .wp-caption-text,
[id^=attachment] figcaption,
.image .wp-caption-text,
.image figcaption {
    font-size: 16px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  [id^=attachment] .wp-caption-text,
[id^=attachment] figcaption,
.image .wp-caption-text,
.image figcaption {
    font-size: 17.6px;
    line-height: 1.45;
  }
}

/*------------------------------------*\
  #MOLECULES
\*------------------------------------*/
/*------------------------------------*\
  #NAVIGATION
\*------------------------------------*/
.nav a {
  color: #1f1c2c;
  border: none;
}
.nav a.active, .nav a:hover {
  color: #a9b5a1;
}
@media (max-width: 799px) {
  .nav a:hover {
    color: #1f1c2c;
  }
}

.nav .current_page_parent a,
.nav .current-menu-item a {
  color: #a9b5a1;
  text-shadow: none;
}

.menu-item-23 a {
  color: #1f1c2c !important;
}

.music .menu-item-23 a {
  color: #a9b5a1 !important;
}

/**
 * Navigation > Primary Nav
 */
.primary-nav .menu-item {
  font-size: 10vw;
  line-height: 0.9;
  color: #a9b5a1;
  text-transform: lowercase;
  padding: 0;
  margin-bottom: 0;
}
.primary-nav a {
  display: block;
}
.primary-nav .nav {
  position: relative;
  z-index: 2;
}
.primary-nav .gif {
  width: 49%;
  max-width: 900px;
}
.primary-nav .gif img {
  width: 100%;
}

@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}
.primary-nav.hide {
  display: none;
}

/**
 * Navigation > Secondary Nav
 */
.secondary-nav {
  margin-top: 20px/1.21;
  margin-bottom: 30px/1.21;
  position: relative;
}
@media screen and (min-width: 799px) {
  .secondary-nav {
    margin-top: 18.1818181818px;
  }
}
@media screen and (min-width: 1099px) {
  .secondary-nav {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1499px) {
  .secondary-nav {
    margin-top: 22px;
  }
}
@media screen and (min-width: 799px) {
  .secondary-nav {
    margin-bottom: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .secondary-nav {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .secondary-nav {
    margin-bottom: 33px;
  }
}
.secondary-nav li {
  display: inline-block;
}
.secondary-nav li:not(:last-of-type) {
  margin-right: 0.6em;
}

/**
 * Navigation > Content Nav
 */
.content-nav {
  margin-top: 40px/1.21;
}
@media screen and (min-width: 799px) {
  .content-nav {
    margin-top: 36.3636363636px;
  }
}
@media screen and (min-width: 1099px) {
  .content-nav {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1499px) {
  .content-nav {
    margin-top: 44px;
  }
}
.content-nav li {
  padding: 1em 0;
}
@media (min-width: 800px) {
  .content-nav li:not(:hover) .nav__thumb {
    display: none;
  }
}
.content-nav li:first-of-type {
  padding-top: 0;
}
.content-nav li:not(:last-of-type) {
  border-bottom: 2px solid #a9b5a1;
}
.content-nav a {
  font-size: 23.1404958678px;
  line-height: 1.25;
}
@media screen and (min-width: 799px) {
  .content-nav a {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .content-nav a {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .content-nav a {
    font-size: 30.8px;
    line-height: 1.25;
  }
}
.content-nav a:hover {
  color: #1f1c2c;
  text-shadow: none;
}
.content-nav h2 {
  margin-bottom: 0;
}
.content-nav h2 a {
  font-size: 41.3223140496px;
  line-height: 1.25;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 799px) {
  .content-nav h2 a {
    font-size: 45.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .content-nav h2 a {
    font-size: 50px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .content-nav h2 a {
    font-size: 55px;
    line-height: 1.25;
  }
}

@media (max-width: 799px) {
  .content-nav .nav__thumb {
    background: none !important;
    margin-bottom: 15px/1.21;
  }
}
@media screen and (max-width: 799px) and (min-width: 799px) {
  .content-nav .nav__thumb {
    margin-bottom: 13.6363636364px;
  }
}
@media screen and (max-width: 799px) and (min-width: 1099px) {
  .content-nav .nav__thumb {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 799px) and (min-width: 1499px) {
  .content-nav .nav__thumb {
    margin-bottom: 16.5px;
  }
}
@media (max-width: 799px) {
  .content-nav .nav__thumb img {
    display: block;
    width: 100%;
  }
}
@media (min-width: 800px) {
  .content-nav .nav__thumb {
    position: fixed;
    z-index: 99;
    width: 240px;
    height: 180px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 25px;
    background-size: cover;
    background-position: center;
  }
  .content-nav .nav__thumb img {
    display: none;
  }
}

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/
/**
 * Components > Full intro
 */
.full-intro {
  width: 100%;
  height: 100vh;
}

/**
 * Components > panel button
 */
.panel-button {
  width: 40px;
  text-align: center;
  outline: none;
}
.panel-button:after {
  content: "✘";
}
.panel-button.hide:after {
  content: "▨";
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * Sponsors
 */
.section--logo {
  text-align: left;
}
.section--logo a {
  display: inline-block;
  width: calc(33% - 1px);
  max-width: 200px;
  min-width: 200px;
  padding: 20px;
  opacity: 0.9;
  border-bottom: none;
}
.section--logo a.small {
  width: calc(25% - 1px);
  max-width: 150px;
  min-width: 150px;
}
.section--logo a.xsmall {
  width: calc(25% - 1px);
  max-width: 90px;
  min-width: 90px;
}
.section--logo a img {
  filter: grayscale(100%);
}
.section--logo a:hover {
  border-bottom: none;
  opacity: 1;
}
.section--logo a:hover img {
  filter: grayscale(0%);
}

.credits {
  margin-top: 20px/1.21;
  margin-bottom: 30px/1.21;
}
@media screen and (min-width: 799px) {
  .credits {
    margin-top: 18.1818181818px;
  }
}
@media screen and (min-width: 1099px) {
  .credits {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1499px) {
  .credits {
    margin-top: 22px;
  }
}
@media screen and (min-width: 799px) {
  .credits {
    margin-bottom: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .credits {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .credits {
    margin-bottom: 33px;
  }
}

.full-intro {
  z-index: 1;
}
.full-intro header,
.full-intro footer {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  padding: 15px 20px;
}
.full-intro header {
  top: 0;
}
.full-intro footer {
  bottom: 0;
}
.full-intro h1,
.full-intro h2 {
  font-size: 23.1404958678px;
  line-height: 1.25;
  word-break: break-word;
}
@media screen and (min-width: 799px) {
  .full-intro h1,
.full-intro h2 {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .full-intro h1,
.full-intro h2 {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .full-intro h1,
.full-intro h2 {
    font-size: 30.8px;
    line-height: 1.25;
  }
}
@media (max-width: 799px) {
  .full-intro h1,
.full-intro h2 {
    font-size: 24px;
  }
}
.full-intro h2 {
  margin-bottom: 10px;
}
.full-intro li a,
.full-intro p {
  font-size: 13.2231404959px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  .full-intro li a,
.full-intro p {
    font-size: 14.5454545455px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  .full-intro li a,
.full-intro p {
    font-size: 16px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  .full-intro li a,
.full-intro p {
    font-size: 17.6px;
    line-height: 1.45;
  }
}
.full-intro .nav li {
  display: inline-block;
  margin-right: 10px;
}
@media (max-width: 799px) {
  .full-intro .nav li {
    display: block;
  }
}
.full-intro .nav a {
  border-bottom: 1px solid #a9b5a1;
}
.full-intro .media {
  height: 100vh;
  height: -webkit-fill-available;
}
.full-intro .media video {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  object-fit: cover;
}
.full-intro .ltr {
  margin-right: 20px;
}
.full-intro .rtl {
  margin-left: 20px;
  transform: scale(-1, 1);
}

.full-intro {
  position: fixed;
  z-index: 1;
}

.load .full-intro {
  display: block;
}

.captcha {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #eceeed;
  z-index: 999;
  overflow: scroll;
  background-image: url("/assets/images/schiev_bg.png");
}
.captcha.visible {
  display: block;
}
.captcha .captcha-container {
  display: flex;
  justify-content: center;
  height: 98vh;
}
.captcha .captcha-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px;
}
@media (min-width: 800px) {
  .captcha .captcha-content figure {
    min-height: 200px;
    max-height: 390px;
    max-width: 500px;
  }
}
.captcha .captcha-content figure img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  border: 2px solid #a9b5a1;
  mix-blend-mode: multiply;
}
.captcha .captcha-content .button-group {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.captcha .captcha-content .button-group .button {
  margin-left: 10px;
}
.captcha .captcha-content .button-group .button img {
  width: 35px;
  height: auto;
}
.captcha .captcha-content .form {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
.captcha .captcha-content .form .form-container {
  display: flex;
  width: 100%;
}
.captcha .captcha-content .form .form-container input,
.captcha .captcha-content .form .form-container button {
  padding: 10px;
  background-color: transparent;
  border: 2px solid #a9b5a1;
  border-radius: 0;
  height: 40px;
}
.captcha .captcha-content .form .form-container button {
  padding: 10px;
  margin-left: 20px;
}

/*------------------------------------*\
  #TEMPLATES
\*------------------------------------*/
.container .primary-nav,
.container .panel {
  padding: 15px;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.container .primary-nav {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.container .primary-nav .gif {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.container .panel {
  position: relative;
  z-index: 2;
  margin-left: auto;
  background-color: #eceeed;
  border-left: 2px solid #a9b5a1;
  overflow-x: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  left: 0;
  transition: left 0.5s ease-in-out;
  animation: slideIn 1s 1 ease-in-out;
}
@media (min-width: 800px) {
  .container .panel {
    width: 50%;
  }
}
@media (max-width: 799px) {
  .container .panel {
    width: 90%;
  }
}
.container .panel.hide {
  left: 50%;
}
@media (max-width: 799px) {
  .container .panel.hide {
    left: 90%;
  }
}
.container .panel .panel-button {
  position: fixed;
  z-index: 999;
  right: 15px;
  top: 15px;
}
@media (max-width: 799px) {
  .container .panel .panel-button {
    right: 7.5px;
    top: 7.5px;
  }
}

@keyframes slideIn {
  0% {
    left: 50%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 0;
  }
}
/*------------------------------------*\
  #HOMEPAGE
\*------------------------------------*/
.container .full-intro {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.home .container .full-intro {
  display: block;
}
.container .full-intro.hide {
  opacity: 0;
}
.container .full-intro.hide-f {
  display: none;
}

/*------------------------------------*\
  #PAGES
\*------------------------------------*/
.content {
  margin-top: 40px/1.21;
  margin-bottom: 60px/1.21;
}
@media screen and (min-width: 799px) {
  .content {
    margin-top: 36.3636363636px;
  }
}
@media screen and (min-width: 1099px) {
  .content {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1499px) {
  .content {
    margin-top: 44px;
  }
}
@media screen and (min-width: 799px) {
  .content {
    margin-bottom: 54.5454545455px;
  }
}
@media screen and (min-width: 1099px) {
  .content {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1499px) {
  .content {
    margin-bottom: 66px;
  }
}
nav + .content {
  margin-top: 0;
}
.content h1 + h3 {
  margin-top: 10px/1.21;
}
@media screen and (min-width: 799px) {
  .content h1 + h3 {
    margin-top: 9.0909090909px;
  }
}
@media screen and (min-width: 1099px) {
  .content h1 + h3 {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1499px) {
  .content h1 + h3 {
    margin-top: 11px;
  }
}
.content h2 {
  margin-bottom: 20px/1.21;
}
@media screen and (min-width: 799px) {
  .content h2 {
    margin-bottom: 18.1818181818px;
  }
}
@media screen and (min-width: 1099px) {
  .content h2 {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1499px) {
  .content h2 {
    margin-bottom: 22px;
  }
}
.content p + h2,
.content h3 + h2,
.content div + h2 {
  margin-top: 40px/1.21;
}
@media screen and (min-width: 799px) {
  .content p + h2,
.content h3 + h2,
.content div + h2 {
    margin-top: 36.3636363636px;
  }
}
@media screen and (min-width: 1099px) {
  .content p + h2,
.content h3 + h2,
.content div + h2 {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1499px) {
  .content p + h2,
.content h3 + h2,
.content div + h2 {
    margin-top: 44px;
  }
}
.content h3 {
  margin-bottom: 15px/1.21;
}
@media screen and (min-width: 799px) {
  .content h3 {
    margin-bottom: 13.6363636364px;
  }
}
@media screen and (min-width: 1099px) {
  .content h3 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1499px) {
  .content h3 {
    margin-bottom: 16.5px;
  }
}
.content p + h3,
.content div + h3 {
  margin-top: 30px/1.21;
}
@media screen and (min-width: 799px) {
  .content p + h3,
.content div + h3 {
    margin-top: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .content p + h3,
.content div + h3 {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .content p + h3,
.content div + h3 {
    margin-top: 33px;
  }
}
.content .embed-container {
  margin-top: 30px/1.21;
  margin-bottom: 30px/1.21;
}
@media screen and (min-width: 799px) {
  .content .embed-container {
    margin-top: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .content .embed-container {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .content .embed-container {
    margin-top: 33px;
  }
}
@media screen and (min-width: 799px) {
  .content .embed-container {
    margin-bottom: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .content .embed-container {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .content .embed-container {
    margin-bottom: 33px;
  }
}
.content ul {
  margin-bottom: 30px/1.21;
}
@media screen and (min-width: 799px) {
  .content ul {
    margin-bottom: 27.2727272727px;
  }
}
@media screen and (min-width: 1099px) {
  .content ul {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1499px) {
  .content ul {
    margin-bottom: 33px;
  }
}

/*------------------------------------*\
  #SCHEDULE
\*------------------------------------*/
.page-id-11 h3 {
  margin: 2em 0 1em 0 !important;
}
.page-id-11 h2:not(:first-of-type) {
  margin-top: 60px/1.21;
}
@media screen and (min-width: 799px) {
  .page-id-11 h2:not(:first-of-type) {
    margin-top: 54.5454545455px;
  }
}
@media screen and (min-width: 1099px) {
  .page-id-11 h2:not(:first-of-type) {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1499px) {
  .page-id-11 h2:not(:first-of-type) {
    margin-top: 66px;
  }
}

.primary-nav .emo {
  display: none;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0;
}

/*------------------------------------*\
  #TEMPLATES PAGE TEMPORAIRE
\*------------------------------------*/
.wrapper--home-temp header,
.wrapper--home-temp footer {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  padding: 15px 20px;
}
.wrapper--home-temp header {
  top: 0;
}
.wrapper--home-temp footer {
  bottom: 0;
}
.wrapper--home-temp h1,
.wrapper--home-temp h2 {
  font-size: 23.1404958678px;
  line-height: 1.25;
  word-break: break-word;
}
@media screen and (min-width: 799px) {
  .wrapper--home-temp h1,
.wrapper--home-temp h2 {
    font-size: 25.4545454545px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1099px) {
  .wrapper--home-temp h1,
.wrapper--home-temp h2 {
    font-size: 28px;
    line-height: 1.25;
  }
}
@media screen and (min-width: 1499px) {
  .wrapper--home-temp h1,
.wrapper--home-temp h2 {
    font-size: 30.8px;
    line-height: 1.25;
  }
}
@media (max-width: 799px) {
  .wrapper--home-temp h1,
.wrapper--home-temp h2 {
    font-size: 24px;
  }
}
.wrapper--home-temp h2 {
  margin-bottom: 10px;
}
.wrapper--home-temp li a,
.wrapper--home-temp p {
  font-size: 13.2231404959px;
  line-height: 1.45;
}
@media screen and (min-width: 799px) {
  .wrapper--home-temp li a,
.wrapper--home-temp p {
    font-size: 14.5454545455px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1099px) {
  .wrapper--home-temp li a,
.wrapper--home-temp p {
    font-size: 16px;
    line-height: 1.45;
  }
}
@media screen and (min-width: 1499px) {
  .wrapper--home-temp li a,
.wrapper--home-temp p {
    font-size: 17.6px;
    line-height: 1.45;
  }
}
.wrapper--home-temp .nav li {
  display: inline-block;
  margin-right: 10px;
}
@media (max-width: 799px) {
  .wrapper--home-temp .nav li {
    display: block;
  }
}
.wrapper--home-temp .nav a {
  border-bottom: 1px solid #a9b5a1;
}
.wrapper--home-temp .article .media {
  height: 100vh;
  height: -webkit-fill-available;
}
.wrapper--home-temp .article .media video {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  object-fit: contain;
}
.wrapper--home-temp .ltr {
  margin-right: 20px;
}
.wrapper--home-temp .rtl {
  margin-left: 20px;
  transform: scale(-1, 1);
}

/*------------------------------------*\
    #BASE-STYLES
\*------------------------------------*/
html {
  font-size: 100%;
}
@media screen and (max-width: 767px) {
  html {
    -webkit-text-size-adjust: 100%;
  }
}

body {
  background: #eceeed;
  background-image: url("/assets/images/schiev_bg.png");
  color: #1f1c2c;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
body ::-moz-selection {
  background: rgba(169, 181, 161, 0.2);
}
body ::selection {
  background: rgba(169, 181, 161, 0.2);
}

/*------------------------------------*\
    #PRINT
\*------------------------------------*/
@media print {
  body {
    font-size: 62.5%;
  }
}