/*------------------------------------*\
  #TEMPLATES
\*------------------------------------*/

.container {
  .primary-nav,
  .panel {
    padding: $spacing-xs;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.container .primary-nav {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  .gif {
    position: fixed;
    top: $spacing-xs;
    right: $spacing-xs;
    z-index: 1;
  }
}

.container .panel {
  position: relative;
  z-index: 2;
  margin-left: auto;
  background-color: $body-color;
  border-left: 2px solid $action;
  overflow-x: hidden;
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;
  @media (min-width: $screen-sm-min) {
    width: 50%;
  }
  @media (max-width: $screen-xs-max) {
    width: 90%;
  }
  left: 0;
  transition: left 0.5s ease-in-out;
  animation: slideIn 1s 1 ease-in-out;
  &.hide {
    left: 50%;
    @media (max-width: $screen-xs-max) {
      left: 90%;
    }
  }
  .panel-button {
    position: fixed;
    z-index: 999;
    right: $spacing-xs;
    top: $spacing-xs;
    @media (max-width: $screen-xs-max) {
      right: $spacing-xs/2;
      top: $spacing-xs/2;
    }
  }
}

@keyframes slideIn {
  0% {
    left: 50%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 0;
  }
}

/*------------------------------------*\
  #HOMEPAGE
\*------------------------------------*/

.container .full-intro {
  display: none;
  .home & {
    display: block;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  &.hide {
    opacity: 0;
  }
  &.hide-f {
    display: none;
  }
}

/*------------------------------------*\
  #PAGES
\*------------------------------------*/

.content {
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-xl-responsive);
  nav + & {
    margin-top: 0;
  }
  h1 + h3 {
    @include margin-top($spacing-xxs-responsive);
  }
  h2 {
    @include margin-bottom($spacing-sm-responsive);
  }
  p + h2,
  h3 + h2,
  div + h2 {
    @include margin-top($spacing-lg-responsive);
  }
  h3 {
    @include margin-bottom($spacing-xs-responsive);
  }
  p + h3,
  div + h3 {
    @include margin-top($spacing-md-responsive);
  }
  .embed-container {
    @include margin-top($spacing-md-responsive);
    @include margin-bottom($spacing-md-responsive);
  }
  ul {
    @include margin-bottom($spacing-md-responsive);
  }
}

// .content + .content-nav {
//   li:first-of-type {
//     border-top: $border-width-sm solid $base-color;
//   }
// }

/*------------------------------------*\
  #SCHEDULE
\*------------------------------------*/

.page-id-11 {
  h3 {
    // border-top: $border-width-sm solid $base-color;
    // border-bottom: $border-width-sm dashed $base-color;
    // padding: .5em 0;
    margin: 2em 0 1em 0 !important;
  }
  h2:not(:first-of-type) {
    @include margin-top($spacing-xl-responsive);
  }
}
