/*------------------------------------*\
  #TEMPLATES PAGE TEMPORAIRE
\*------------------------------------*/

.wrapper--home-temp{
    header,
    footer{
        display: flex;
        z-index: 1;
        justify-content: space-between;
        width: 100%;
        position: fixed;
        padding: $spacing-xs $spacing-sm;
    }
    header{
        top:0;
    }

    footer{
        bottom:0;
    }

    h1,
    h2{
        @include font-size($font-sizes-lg);
        @extend .font-var-bold;
        word-break: break-word;
        @media (max-width: $screen-xs-max) {
            font-size: 24px;
        }
    }
    h2{
      margin-bottom: $spacing-xxs;
    }
    li a,
    p{
        @include font-size($font-sizes-sm);
        @extend .font-var-bold;
    }


    .nav{
      li{
        display: inline-block;
        margin-right: $spacing-xxs;
        @media (max-width: $screen-xs-max) {
            display: block;
        }
      }
      a{
        border-bottom: $border-width-sm solid $action;
      }
    }

    .article .media {
        height: 100vh;
        height: -webkit-fill-available;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // img {
        //     max-width: 100%;
        //     height: auto;
        //     max-height: 90vh;
        // }
        video{
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          height: -webkit-fill-available;
          object-fit :contain;
        }
    }

    .ltr{
      margin-right: $spacing-sm;
    }
    .rtl{
        margin-left: $spacing-sm;
        transform: scale(-1, 1);
    }
}
