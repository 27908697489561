// ------------------------------------------------------------
// Settings

// :: Colours
// :: Breakpoints
// :: Typography
// :: Borders
// :: Spacing

// ------------------------------------------------------------

/*------------------------------------*\
    #COLOURS
\*------------------------------------*/

// Main colour palette
$black: #1f1c2c;
$white: #ffffff;
$grey: #eceeed; // used in forms
$blue: #0d4eff;
$cyan: #009fe3;
$red: #ff0000;
$grey2018: #d4d4d4;
$blue2019: #2b8ea9;
$light-grey: #d7d9d4; // used in forms
$light-blue: #9acce2;
$grey-2: #a9b5a1;

// Assign colours to variable roles
// Be descriptive without being specific.
// This allows for consistency project to project, and easy changing

$body-color: $grey;
$base-color: $black;
$action: $grey-2;

/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/

// Extra small screen / phone
$screen-xs: 600px !default;
$screen-xs-min: $screen-xs !default;

// Small screen / tablet
$screen-sm: 800px !default;
$screen-sm-min: $screen-sm !default;

// Medium screen / desktop
$screen-md: 1100px !default;
$screen-md-min: $screen-md !default;

// Large screen / wide desktop
$screen-lg: 1500px !default;
$screen-lg-min: $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// mediaqueries for spaces & typography
$breakpoints: (
  sm: $screen-xs-max,
  md: $screen-sm-max,
  lg: $screen-md-max,
);

/*------------------------------------*\
    #SPACES
\*------------------------------------*/

$spacing-base: 5px;

$spacing-xxxl: $spacing-base * 20; // 100px
$spacing-xxl: $spacing-base * 16; // 80px
$spacing-xl: $spacing-base * 12; // 60px
$spacing-lg: $spacing-base * 8; // 40px
$spacing-md: $spacing-base * 6; // 30px
$spacing-sm: $spacing-base * 4; // 20px
$spacing-xs: $spacing-base * 3; // 15px
$spacing-xxs: $spacing-base * 2; // 10px

$spacing-res-ratio: 1.1;

$spacing-xxxl-responsive: (
  null: $spacing-xxxl /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-xxxl / $spacing-res-ratio,
  md: $spacing-xxxl,
  lg: $spacing-xxxl * $spacing-res-ratio,
);

$spacing-xxl-responsive: (
  null: $spacing-xxl /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-xxl / $spacing-res-ratio,
  md: $spacing-xxl,
  lg: $spacing-xxl * $spacing-res-ratio,
);

$spacing-xl-responsive: (
  null: $spacing-xl /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-xl / $spacing-res-ratio,
  md: $spacing-xl,
  lg: $spacing-xl * $spacing-res-ratio,
);

$spacing-lg-responsive: (
  null: $spacing-lg /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-lg / $spacing-res-ratio,
  md: $spacing-lg,
  lg: $spacing-lg * $spacing-res-ratio,
);

$spacing-md-responsive: (
  null: $spacing-md /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-md / $spacing-res-ratio,
  md: $spacing-md,
  lg: $spacing-md * $spacing-res-ratio,
);

$spacing-sm-responsive: (
  null: $spacing-sm /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-sm / $spacing-res-ratio,
  md: $spacing-sm,
  lg: $spacing-sm * $spacing-res-ratio,
);

$spacing-xs-responsive: (
  null: $spacing-xs /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-xs / $spacing-res-ratio,
  md: $spacing-xs,
  lg: $spacing-xs * $spacing-res-ratio,
);

$spacing-xxs-responsive: (
  null: $spacing-xxs /
    (
      $spacing-res-ratio * $spacing-res-ratio,
    ),
  sm: $spacing-xxs / $spacing-res-ratio,
  md: $spacing-xxs,
  lg: $spacing-xxs * $spacing-res-ratio,
);

/*------------------------------------*\
    #BORDERS WIDTH
\*------------------------------------*/

$border-width-sm: 1px;
$border-width-md: 3px;
