/*------------------------------------*\
  #MOLECULES
\*------------------------------------*/

/*------------------------------------*\
  #NAVIGATION
\*------------------------------------*/

.nav a {
  @extend .font-var;
  color: $base-color;
  border: none;

  &.active,
  &:hover {
    color: $action;
  }

  @media (max-width: $screen-xs-max) {
    &:hover {
      color: $base-color;
    }
  }
}

.nav .current_page_parent a,
.nav .current-menu-item a {
  color: $action;
  text-shadow: none;
}

// Exception for page 23
.menu-item-23 a {
  color: $base-color !important;
}

.music .menu-item-23 a {
  color: $action !important;
}

/**
 * Navigation > Primary Nav
 */

.primary-nav {
  // background: $body-color;
  .menu-item {
    font-size: 10vw;
    line-height: 0.9;
    color: $action;
    text-transform: lowercase;
    padding: 0;
    margin-bottom: 0;
  }

  a {
    display: block;
  }

  .nav {
    position: relative;
    z-index: 2;
  }

  .gif {
    width: 49%;
    max-width: 900px;

    img {
      width: 100%;
      // animation-name: spinner;
      // animation-timing-function: linear;
      // animation-iteration-count: infinite;
      // animation-duration: 3s;
      // transform-style: preserve-3d;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-360deg);
  }
}

.primary-nav.hide {
  display: none;
}

body.home .primary-nav .menu-item-19,
main.music {
  .menu-item-23 a {
    // color: $body-color;
  }
}

main.conference {
  // .menu-item-23 a {
  //   color: $action;
  //   text-shadow: none;
  // }

  .menu-item-162 a {
    // color: $body-color;
  }
}

// main.market {
//   .menu-item-23 a {
//     color: $action;
//     text-shadow: none;
//   }

//   .menu-item-163 a {
//     color: $body-color;
//   }
// }

/**
 * Navigation > Secondary Nav
 */

.secondary-nav {
  @include margin-top($spacing-sm-responsive);
  @include margin-bottom($spacing-md-responsive);
  position: relative;

  li {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 0.6em;
    }
  }
}

/**
 * Navigation > Content Nav
 */

.content-nav {
  @include margin-top($spacing-lg-responsive);

  li {
    @media (min-width: $screen-sm-min) {
      &:not(:hover) .nav__thumb {
        display: none;
      }
    }

    padding: 1em 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 2px solid $action;
    }
  }

  a {
    @include font-size($font-sizes-lg);

    &:hover {
      color: $base-color;
      text-shadow: none;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  h2 a {
    @include font-size($font-sizes-xl);
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.content-nav .nav__thumb {
  @media (max-width: $screen-xs-max) {
    background: none !important;
    @include margin-bottom($spacing-xs-responsive);

    img {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: $screen-sm-min) {
    position: fixed;
    z-index: 99;
    width: 240px;
    height: 180px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 25px;
    background-size: cover;
    background-position: center;

    img {
      display: none;
    }
  }
}

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/

/**
 * Components > Full intro
 */

.full-intro {
  width: 100%;
  height: 100vh;
  // background-color: $base-color;
  // background-image: url('../css/images/schiev-l*.phpo-white.svg');
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 30%;
  // @media (max-width: $screen-xs-max) {
  //   background-size: 50%;
  // }
  // h1 {
  //   display: none;
  // }
  // &:hover {
  //   cursor: pointer;
  // }
}

/**
 * Components > panel button
 */

.panel-button {
  width: 40px;
  text-align: center;
  outline: none;

  &:after {
    content: "✘";
  }

  &.hide:after {
    content: "▨";
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * Sponsors
 */

.section--logo {
  text-align: left;

  a {
    display: inline-block;
    width: calc(33% - 1px);
    max-width: 200px;
    min-width: 200px;
    padding: 20px;
    opacity: 0.9;
    border-bottom: none;

    &.small {
      width: calc(25% - 1px);
      max-width: 150px;
      min-width: 150px;
    }

    &.xsmall {
      width: calc(25% - 1px);
      max-width: 90px;
      min-width: 90px;
    }

    img {
      filter: grayscale(100%);
    }

    &:hover {
      border-bottom: none;
      opacity: 1;

      img {
        filter: grayscale(0%);
      }
    }
  }
}

.credits {
  @include margin-top($spacing-sm-responsive);
  @include margin-bottom($spacing-md-responsive);
}

// Video 2022

.full-intro {
  z-index: 1;

  header,
  footer {
    display: flex;
    z-index: 1;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    padding: $spacing-xs $spacing-sm;
  }

  header {
    top: 0;
  }

  footer {
    bottom: 0;
  }

  h1,
  h2 {
    @include font-size($font-sizes-lg);
    @extend .font-var-bold;
    word-break: break-word;

    @media (max-width: $screen-xs-max) {
      font-size: 24px;
    }
  }

  h2 {
    margin-bottom: $spacing-xxs;
  }

  li a,
  p {
    @include font-size($font-sizes-sm);
    @extend .font-var-bold;
  }

  .nav {
    li {
      display: inline-block;
      margin-right: $spacing-xxs;

      @media (max-width: $screen-xs-max) {
        display: block;
      }
    }

    a {
      border-bottom: $border-width-sm solid $action;
    }
  }

  .media {
    height: 100vh;
    height: -webkit-fill-available;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // img {
    //     max-width: 100%;
    //     height: auto;
    //     max-height: 90vh;
    // }
    video {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      height: -webkit-fill-available;
      object-fit: cover;
    }
  }

  .ltr {
    margin-right: $spacing-sm;
  }

  .rtl {
    margin-left: $spacing-sm;
    transform: scale(-1, 1);
  }
}

.full-intro {
  position: fixed;
  z-index: 1;
}

// .full-intro.hide {
//   // display: none;
// }

.load {
  .full-intro {
    display: block;
  }
}

// 2024 captcha

.captcha {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $grey;
  z-index: 999;
  overflow: scroll;
  background-image: url("/assets/images/schiev_bg.png");
  &.visible {
    display: block;
  }
  .captcha-container {
    display: flex;
    justify-content: center;
    height: 98vh;
  }

  .captcha-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $spacing-md;
    figure {
      @media (min-width: $screen-sm) {
        // height: calc(100vh - 180px);
        min-height: 200px;
        max-height: 390px;
        max-width: 500px;
      }
      img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
        border: 2px solid $grey-2;
        mix-blend-mode: multiply;
      }
    }
    .button-group {
      display: flex;
      justify-content: end;
      margin-top: $spacing-xxs;

      .button {
        margin-left: $spacing-xxs;
        img {
          width: 35px;
          height: auto;
        }
      }
    }

    .form {
      display: flex;
      justify-content: center;
      margin: $spacing-xs 0;
      .form-container {
        display: flex;
        width: 100%;
        input,
        button {
          padding: $spacing-xxs;
          background-color: transparent;
          border: 2px solid $grey-2;
          border-radius: 0;
          height: 40px;
        }
        button {
          padding: $spacing-xxs;
          margin-left: $spacing-sm;
        }
      }
    }
  }
}
