.primary-nav .emo{
  display: none;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0;
}

// .load .primary-nav{
//   .emo{
//     display: block;
//   }
//   .nav{
//     display: none;
//   }
// }
// 
