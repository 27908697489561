/*------------------------------------*\
    #GLOBAL TYPOGRAPHY
\*------------------------------------*/

// @font-face {
//   font-family: 'Lausanne-300';
//   src: url("../fonts/Lausanne-300.woff2") format("woff2"),
//   url("../fonts/Lausanne-300.woff") format("woff");
//   font-weight: normal;
//   font-style: normal
// }

/*------------------------------------*\
    #FONTS
\*------------------------------------*/

.font-body {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-body-italic {
  font-family: sans-serif;
  font-weight: 400;
  font-style: italic;
  text-shadow: 0 0 0.1em rgba($black, 0.5);
}

.font-body-bold {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
  text-shadow: 0 0 0.1em rgba($black, 0.5);
}

.font-body-bold-italic {
  font-family: sans-serif;
  font-weight: 700;
  font-style: italic;
}

.font-var {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-var-bold {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
}

/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-size-sm: 16px;
$font-size-md: 18px;
$font-size-lg: 28px;
$font-size-xl: 50px;

//** Unit-less `line-height`
$line-height: 1.45;
$line-height-alt: 1.25;
// for use in components like buttons, labels
$line-height-form: 1.428571429 !default; // 20/14
$font-res-ratio: 1.1; // responsive ratio

/*------------------------------------*\
    #FONTSIZES
\*------------------------------------*/

$font-sizes-sm: (
  null: (
    $font-size-sm / ($font-res-ratio * $font-res-ratio),
    $line-height,
  ),
  sm: (
    $font-size-sm / $font-res-ratio,
    $line-height,
  ),
  md: (
    $font-size-sm,
    $line-height,
  ),
  lg: (
    $font-size-sm * $font-res-ratio,
    $line-height,
  ),
);

$font-sizes-md: (
  null: (
    $font-size-md / ($font-res-ratio * $font-res-ratio),
    $line-height,
  ),
  sm: (
    $font-size-md / $font-res-ratio,
    $line-height,
  ),
  md: (
    $font-size-md,
    $line-height,
  ),
  lg: (
    $font-size-md * $font-res-ratio,
    $line-height,
  ),
);

$font-sizes-lg: (
  null: (
    $font-size-lg / ($font-res-ratio * $font-res-ratio),
    $line-height-alt,
  ),
  sm: (
    $font-size-lg / $font-res-ratio,
    $line-height-alt,
  ),
  md: (
    $font-size-lg,
    $line-height-alt,
  ),
  lg: (
    $font-size-lg * $font-res-ratio,
    $line-height-alt,
  ),
);

$font-sizes-xl: (
  null: (
    $font-size-xl / ($font-res-ratio * $font-res-ratio),
    $line-height-alt,
  ),
  sm: (
    $font-size-xl / $font-res-ratio,
    $line-height-alt,
  ),
  md: (
    $font-size-xl,
    $line-height-alt,
  ),
  lg: (
    $font-size-xl * $font-res-ratio,
    $line-height-alt,
  ),
);

/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-size($font-sizes-lg);
  @extend .font-var-bold;
}

h3,
h4,
h5,
h6 {
  @extend .font-var;
  @include font-size($font-sizes-lg);
  // border-left: $border-width-sm solid $base-color;
  padding: 0.3em;
}

p {
  @include font-size($font-sizes-md);
  margin-bottom: 0.5em;
}

blockquote p {
  text-align: center;
}

a {
  color: $action;
  border-bottom: $border-width-sm solid $action;
  padding-bottom: 0.1em;
  text-decoration: none;
  @include link-active-styles {
    // Mixin for interactions (hover,focus,active)
    color: $action;
  }
}

em,
i {
  @extend .font-body-italic;
}

b,
strong {
  @extend .font-body-bold;
}

em > b,
b > em,
i > b,
b > i,
em > strong,
strong > em,
i > strong,
strong > i {
  @extend .font-body-bold-italic;
}

p sub,
p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small {
  @include font-size($font-sizes-md);
}

p abbr {
  border-bottom: $border-width-sm dotted $base-color;
  cursor: help;
}

p q,
p cite {
  &:before {
    content: '"';
  }
  &:after {
    content: '"';
  }
}

p mark {
  background-color: rgba($action, 0.2);
  color: $base-color;
}

p code,
p samp {
  font-family: monospace;
  @include font-size($font-sizes-md);
}

pre {
  font-family: monospace;
  @include font-size($font-sizes-md);
}

hr {
  background: $base-color;
  height: $border-width-sm;
  border: none;
  margin: 0;
}

figcaption {
  @include font-size($font-sizes-md);
  @include margin-top($spacing-xs-responsive);
}

dl {
  dt {
    @include font-size($font-sizes-md);
    @include margin-bottom($spacing-xs-responsive);
    &:first-of-type {
      @include padding-top($spacing-sm-responsive);
      border-top: $border-width-sm $base-color solid;
    }
  }
  dd {
    padding-bottom: $spacing-sm;
    margin-bottom: $spacing-sm;
    border-bottom: $border-width-sm $base-color solid;
  }
}

ol,
ul,
pre,
dl {
  @include font-size($font-sizes-md);
}
ul,
ol {
  @include nobullet;
}
ul ul {
  @include font-size($font-sizes-md);
}
ol ol {
  @include font-size($font-sizes-md);
}
.text ul {
  @include unordered;
  margin-left: 1em;
}
.text ol {
  @include ordered;
  margin-left: 1em;
}
.text > ul,
.text > ol {
  margin-bottom: 1em;
}
li {
  line-height: inherit;
}
input,
textarea,
label,
select {
  line-height: $line-height-form;
}
